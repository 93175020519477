import {ChangeDetectorRef, Component, ComponentRef, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FormArray, FormControl, Validators} from '@angular/forms';
import {fadeInRightAnimation} from '../../../@fury/animations/fade-in-right.animation';
import {scaleInAnimation} from '../../../@fury/animations/scale-in.animation';
import {fadeInUpAnimation} from '../../../@fury/animations/fade-in-up.animation';
import {Subject} from 'rxjs';
import {FiltersService} from '../../services/base/filters.service';
import {debounceTime, distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {Company} from '../../interfaces/info/company';
import {HeadCategory} from '../../interfaces/routering/head-category';
import {MainCategoriesSurroundings} from '../../interfaces/configuration/main-categories-surroundings';
import {MainCategory} from '../../interfaces/routering/main-category';
import {SubCategory} from '../../interfaces/routering/sub-category';
import {ReporterType} from '../../interfaces/configuration/reporter-type';
import * as moment from 'moment';
import {IntegratedOptions} from '../../interfaces/base/integrated-options';
import {NationaalGeoregisterSuggest} from '../../interfaces/external-services/nationaal-georegister-suggest';
import {PdokService} from '../../services/external/pdok.service';
import {LeafletMapService} from '../../services/base/leaflet-map.service';
import {NationaalGeoregisterRevGeoServerDocs} from '../../interfaces/external-services/nationaal-georegister-rev-geo';
import {NationaalGeoregisterLookupServerDocs} from '../../interfaces/external-services/nationaal-georegister-lookup';
import {WebformService} from '../../services/reports/webform.service';
import {Router} from '@angular/router';
import {RemovableAttachmentComponent} from '../../../@fury/shared/removable-attachment/removable-attachment.component';
import {LayoutService} from '../../layout/layout.service';

@Component({
  selector: 'fury-normal-form',
  templateUrl: './normal-form.component.html',
  styleUrls: ['./normal-form.component.scss'],
  animations: [fadeInUpAnimation, fadeInRightAnimation, scaleInAnimation]
})
export class NormalFormComponent implements OnInit, OnDestroy {

  protected readonly Validators = Validators;

  public filters = {
    'mainCategoriesSurroundings': {
      preload: true,
      isLoading: false,
      data: <MainCategoriesSurroundings[]>[]
    },
    'headCategories': {
      preload: false,
      isLoading: false,
      data: <HeadCategory[]>[]
    },
    'mainCategories': {
      preload: false,
      isLoading: false,
      data: <MainCategory[]>[]
    },
    'subCategories': {
      preload: false,
      isLoading: false,
      data: <SubCategory[]>[]
    },
    'yesNo': {
      preload: true,
      isLoading: false,
      data: <IntegratedOptions[]>[]
    },
    'reporterTypes': {
      preload: true,
      isLoading: false,
      data: <ReporterType[]>[]
    },
    'personPrefix': {
      preload: true,
      isLoading: false,
      data: <IntegratedOptions[]>[]
    },
    'companies': {
      preload: false,
      isLoading: false,
      data: <Company[]>[]
    },
  };
  public currentSection: string = 'melding';
  public controlForm: boolean = false;
  public selectedHeadCategoryNote: string = null;
  public selectedMainCategoryNote: string = null;
  public selectedSubCategoryNote: string = null;
  public maxNoticedAt: string = null;
  @ViewChild('attachmentsPlaceholder', {read: ViewContainerRef}) attachmentsPlaceholder: ViewContainerRef;
  public attachmentsRef: Array<ComponentRef<RemovableAttachmentComponent>> = [];
  public suggestSuspectLocations: any = null;
  public isLoadingSuspectLocations: boolean = false;
  public shouldGeocodeReportLocation: boolean = true;
  public suggestReportLocations: any = null;
  public isLoadingReportLocations: boolean = false;
  public suggestReporterLocations: any = null;
  public isLoadingReporterLocations: boolean = false;
  public isLoading: boolean = null;

  public attachmentIgnored: string = null;
  public attachmentCountExceeded: boolean = false;
  public attachmentTotalSizeExceeded: boolean = false;

  private acceptedMaxAttachments: number = 6;
  private acceptedMaxSizeAttachments: number = 30720000;
  private acceptedAttachments: string[] = ['jpg', 'jpeg', 'png', 'pdf', 'mp4', 'flv', 'doc', 'docx', 'xlsx', 'xls'];

  private destroy$: Subject<null> = new Subject<null>();

  constructor(private filtersService: FiltersService,
              private pdokService: PdokService,
              private cd: ChangeDetectorRef,
              private router: Router,
              private layoutService: LayoutService,
              public webformService: WebformService,
              public leafletMapService: LeafletMapService,
  ) {
  }

  ngOnInit(): void {
    this.filtersService.filters = this.filters;
    this.webformService.formType = 'normal';
    this.layoutService.isPublicNotificationVisible.emit(true);

    this.webformService.normalForm.controls.form_filling_start_at.setValue(moment().toISOString());

    Object.keys(this.filters).map((key: string): void => {
      if (this.filters[key].preload) {
        this.filtersService[key]().pipe(takeUntil(this.destroy$)).subscribe((data: any): void => {
          if (data) {
            if (key === 'reporterTypes') {
              this.filters[key].data = data.reverse();
            } else {
              this.filters[key].data = data;
            }
          }
        });
      }
    });

    this.webformService.normalForm.controls.surrounding_id.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: number): void => {
      const surrounding: MainCategoriesSurroundings = this.filters.mainCategoriesSurroundings.data.find((d: MainCategoriesSurroundings): boolean => d.id === value);
      if (typeof surrounding !== 'undefined') {
        this.shouldGeocodeReportLocation = surrounding.geocoding === 1;
        this.webformService.normalForm.controls.same_as_reporter_address.setValue(null);

        const element: FormControl = this.webformService.normalForm.controls.report_location;
        element.setValue(null);
        this.leafletMapService.clear();

        const sameAsReporterAddress: FormControl = this.webformService.normalForm.controls.same_as_reporter_address;
        if (surrounding.geocoding) {
          sameAsReporterAddress.addValidators(Validators.required);
        } else {
          sameAsReporterAddress.removeValidators(Validators.required);
        }
        sameAsReporterAddress.updateValueAndValidity();
      }

      this.webformService.normalForm.controls.head_category_id.setValue(null);
      this.filters.headCategories.data = [];

      if (value) {
        this.filters.headCategories.isLoading = true;
        this.filtersService.headCategories(value).pipe(takeUntil(this.destroy$)).subscribe((response: HeadCategory[] | false): void => {
          this.filters.headCategories.isLoading = false;

          if (response) {
            this.filters.headCategories.data = response;
          }
        });
      }
    });

    this.webformService.normalForm.controls.head_category_id.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
      const id: number = (value ? parseInt(value, 10) : null);

      this.webformService.normalForm.controls.main_category_id.setValue(null);
      this.filters.mainCategories.data = [];

      this.selectedHeadCategoryNote = null;
      if (id) {
        const headCategory: HeadCategory = this.filters.headCategories.data.find((d: HeadCategory): boolean => d.id === id);
        if (typeof headCategory !== 'undefined') {
          if (headCategory.description !== null) {
            this.selectedHeadCategoryNote = headCategory.description;
          }

          if (headCategory.main_categories_count) {
            this.filters.mainCategories.isLoading = true;
            this.filtersService.mainCategories(id, this.webformService.normalForm.controls.surrounding_id.value).pipe(takeUntil(this.destroy$)).subscribe((response: MainCategory[] | false): void => {
              this.filters.mainCategories.isLoading = false;

              if (response) {
                this.filters.mainCategories.data = response;
              }
            });
          }
        }
      }
    });

    if (this.webformService.normalForm.controls.head_category_id.value) {
      this.filtersService.headCategories(this.webformService.normalForm.controls.surrounding_id.value).pipe(takeUntil(this.destroy$)).subscribe((response: HeadCategory[] | false): void => {
        this.filters.headCategories.isLoading = false;

        if (response) {
          this.filters.headCategories.data = response;
          this.cd.detectChanges();
        }
      });
    }

    this.webformService.normalForm.controls.main_category_id.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
      const id: number = (value ? parseInt(value, 10) : null);

      this.webformService.normalForm.controls.main_category_id.setValue(id, {emitEvent: false});
      this.webformService.normalForm.controls.sub_category_id.setValue(null);
      this.filters.subCategories.data = [];

      this.selectedMainCategoryNote = null;
      if (id) {
        const mainCategory: MainCategory = this.filters.mainCategories.data.find((d: MainCategory): boolean => d.id === id);
        if (typeof mainCategory !== 'undefined') {
          if (mainCategory.description !== null) {
            this.selectedMainCategoryNote = mainCategory.description;
          }

          if (mainCategory.sub_categories_count) {
            this.webformService.normalForm.controls.sub_category_id.addValidators(Validators.required);
            this.filters.subCategories.isLoading = true;
            this.filtersService.subCategories(this.webformService.normalForm.controls.head_category_id.value, id).pipe(takeUntil(this.destroy$)).subscribe((response: SubCategory[] | false): void => {
              this.filters.subCategories.isLoading = false;

              if (response) {
                this.filters.subCategories.data = response;
              }
            });
          } else {
            this.webformService.normalForm.controls.sub_category_id.removeValidators(Validators.required);
          }
        }
      }
    });

    if (this.webformService.normalForm.controls.main_category_id.value) {
      this.filtersService.mainCategories(this.webformService.normalForm.controls.head_category_id.value, this.webformService.normalForm.controls.surrounding_id.value).pipe(takeUntil(this.destroy$)).subscribe((response: MainCategory[] | false): void => {
        this.filters.mainCategories.isLoading = false;

        if (response) {
          this.filters.mainCategories.data = response;
          this.cd.detectChanges();
        }
      });
    }

    this.webformService.normalForm.controls.sub_category_id.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
      const id: number = (value ? parseInt(value, 10) : null);

      this.webformService.normalForm.controls.sub_category_id.setValue(id, {emitEvent: false});
      this.selectedSubCategoryNote = null;
      if (id) {
        const subCategory: SubCategory = this.filters.subCategories.data.find((d: SubCategory): boolean => d.id === id);
        if (typeof subCategory !== 'undefined') {
          if (subCategory.description !== null) {
            this.selectedSubCategoryNote = subCategory.description;
          }
        }
      }
    });

    if (this.webformService.normalForm.controls.sub_category_id.value) {
      this.filtersService.subCategories(this.webformService.normalForm.controls.head_category_id.value, this.webformService.normalForm.controls.main_category_id.value).pipe(takeUntil(this.destroy$)).subscribe((response: SubCategory[] | false): void => {
        this.filters.subCategories.isLoading = false;

        if (response) {
          this.filters.subCategories.data = response;
          this.cd.detectChanges();
        }
      });
    }

    this.webformService.normalForm.controls.noticed_at_fix.setValue(moment().format('YYYY-MM-DD HH:mm'));
    this.webformService.normalForm.controls.noticed_at.setValue(moment().toISOString());
    this.maxNoticedAt = moment().format('YYYY-MM-DD HH:mm');
    this.webformService.normalForm.controls.noticed_at_fix.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
      if (moment(value) > moment()) {
        this.webformService.normalForm.controls.noticed_at.setValue(moment().toISOString(), {emitEvent: false});
      } else {
        this.webformService.normalForm.controls.noticed_at.setValue(moment(value).toISOString(), {emitEvent: false});
      }
    });

    this.webformService.normalForm.controls.suspect_known.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value): void => {
      this.webformService.normalForm.controls.suspect_type.setValue(null);
      this.webformService.normalForm.controls.suspect_name.setValue(null);
      this.webformService.normalForm.controls.suspect_company_id.setValue(null);
      this.webformService.normalForm.controls.suspect_location.setValue(null);
      this.webformService.normalForm.controls.suspect_postalcode.setValue(null);
      this.webformService.normalForm.controls.suspect_house_number.setValue(null);
      this.webformService.normalForm.controls.suspect_house_number_addition.setValue(null);
      this.webformService.normalForm.controls.suspect_streetname.setValue(null);
      this.webformService.normalForm.controls.suspect_city.setValue(null);
      this.webformService.normalForm.controls.suspect_municipality.setValue(null);
      this.webformService.normalForm.controls.suspect_lat.setValue(null);
      this.webformService.normalForm.controls.suspect_lng.setValue(null);

      if (value) {
        this.webformService.normalForm.controls.suspect_type.setValue(null);
      } else {
        this.webformService.normalForm.controls.suspect_type.setValue('Onbekend');
      }
    });

    this.webformService.normalForm.controls.suspect_name.valueChanges.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      filter((query: string): boolean | null => query && (typeof query === 'object' || typeof query === 'string' && (query.length > 2 ? query.length > 2 : null))),
      takeUntil(this.destroy$)
    ).subscribe((value: string | Company): void => {
      this.filters.companies.data = [];
      this.webformService.normalForm.controls.suspect_company_id.setValue(null);
      this.webformService.normalForm.controls.suspect_location.enable();

      const suspectType = this.webformService.normalForm.controls.suspect_type;

      if (value && suspectType.value === 'Bedrijf') {
        if (typeof value === 'string') {
          this.filters.companies.isLoading = true;

          this.filtersService.companies(value).pipe(takeUntil(this.destroy$)).subscribe((data: Company[]): void => {
              this.filters.companies.isLoading = false;

              if (data) {
                this.filters.companies.data = <Company[]>data;
              }
            },
            (): boolean => this.filters.companies.isLoading = false);
        } else {
          this.webformService.normalForm.controls.suspect_name.setValue(value.name, {emitEvent: false});
          this.webformService.normalForm.controls.suspect_company_id.setValue(value.id);
          this.webformService.normalForm.controls.suspect_location.disable();
          this.webformService.normalForm.controls.suspect_location.setValue(value.location, {emitEvent: false});
          this.webformService.normalForm.controls.suspect_postalcode.setValue(null);
          this.webformService.normalForm.controls.suspect_house_number.setValue(null);
          this.webformService.normalForm.controls.suspect_house_number_addition.setValue(null);
          this.webformService.normalForm.controls.suspect_streetname.setValue(null);
          this.webformService.normalForm.controls.suspect_city.setValue(null);
          this.webformService.normalForm.controls.suspect_municipality.setValue(null);
          this.webformService.normalForm.controls.suspect_lat.setValue(null);
          this.webformService.normalForm.controls.suspect_lng.setValue(null);
        }
      }
    });

    this.webformService.normalForm.controls.suspect_location.valueChanges.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      filter((query: string): boolean | null => query && (typeof query === 'object' || typeof query === 'string' && (query.length > 2 ? query.length > 2 : null))),
      takeUntil(this.destroy$)
    ).subscribe((value: string | any): void => {
      this.suggestSuspectLocations = [];

      this.webformService.normalForm.controls.suspect_postalcode.setValue(null);
      this.webformService.normalForm.controls.suspect_house_number.setValue(null);
      this.webformService.normalForm.controls.suspect_house_number_addition.setValue(null);
      this.webformService.normalForm.controls.suspect_streetname.setValue(null);
      this.webformService.normalForm.controls.suspect_city.setValue(null);
      this.webformService.normalForm.controls.suspect_municipality.setValue(null);
      this.webformService.normalForm.controls.suspect_lat.setValue(null);
      this.webformService.normalForm.controls.suspect_lng.setValue(null);

      if (value) {
        if (typeof value === 'string') {
          this.isLoadingSuspectLocations = true;

          this.pdokService.getSearchedAddress(value).pipe(takeUntil(this.destroy$)).subscribe((response: NationaalGeoregisterSuggest): void => {
              this.isLoadingSuspectLocations = false;

              if (response) {
                if (response.response.numFound > 0) {
                  this.suggestSuspectLocations = response.response.docs;
                } else {
                  this.suggestSuspectLocations = [{
                    id: null,
                    weergavenaam: 'Er zijn geen resultaten gevonden, probeer een ander adres.'
                  }];
                }
              }
            },
            (): boolean => this.isLoadingSuspectLocations = false);
        } else {
          this.pdokService.selectSearchedAddress(value.id).pipe(takeUntil(this.destroy$)).subscribe((response: NationaalGeoregisterLookupServerDocs): void => {
            this.webformService.normalForm.controls.suspect_postalcode.setValue(typeof response.postcode !== 'undefined' ? response.postcode : null);
            this.webformService.normalForm.controls.suspect_house_number.setValue(typeof response.huisnummer !== 'undefined' ? response.huisnummer : null);
            this.webformService.normalForm.controls.suspect_house_number_addition.setValue(typeof response.huisletter !== 'undefined' ? response.huisletter : null);
            this.webformService.normalForm.controls.suspect_streetname.setValue(typeof response.straatnaam !== 'undefined' ? response.straatnaam : null);
            this.webformService.normalForm.controls.suspect_city.setValue(typeof response.woonplaatsnaam !== 'undefined' ? response.woonplaatsnaam : null);
            this.webformService.normalForm.controls.suspect_municipality.setValue(typeof response.gemeentenaam !== 'undefined' ? response.gemeentenaam : null);
            this.webformService.normalForm.controls.suspect_lat.setValue(typeof response.lat !== 'undefined' ? response.lat : null);
            this.webformService.normalForm.controls.suspect_lng.setValue(typeof response.lng !== 'undefined' ? response.lng : null);
          });
        }
      }
    });

    this.webformService.normalForm.controls.report_location.valueChanges.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      filter((query: string): boolean | null => query && (typeof query === 'object' || typeof query === 'string' && (query.length > 2 ? query.length > 2 : null))),
      takeUntil(this.destroy$)
    ).subscribe((value: string | any): void => {
      this.suggestReportLocations = [];

      this.webformService.normalForm.controls.report_postalcode.setValue(null);
      this.webformService.normalForm.controls.report_house_number.setValue(null);
      this.webformService.normalForm.controls.report_house_number_addition.setValue(null);
      this.webformService.normalForm.controls.report_streetname.setValue(null);
      this.webformService.normalForm.controls.report_city.setValue(null);
      this.webformService.normalForm.controls.report_municipality.setValue(null);
      this.webformService.normalForm.controls.report_lat.setValue(null);
      this.webformService.normalForm.controls.report_lng.setValue(null);

      if (value) {
        if (typeof value === 'string') {
          this.isLoadingReportLocations = true;

          this.pdokService.getSearchedAddress(value).pipe(takeUntil(this.destroy$)).subscribe((response: NationaalGeoregisterSuggest): void => {
              this.isLoadingReportLocations = false;

              if (response) {
                if (response.response.numFound > 0) {
                  this.suggestReportLocations = response.response.docs;
                } else {
                  this.suggestReportLocations = [{
                    id: null,
                    weergavenaam: 'Er zijn geen resultaten gevonden, probeer een ander adres.'
                  }];
                }
              }
            },
            (): boolean => this.isLoadingReportLocations = false);
        } else {
          this.pdokService.selectSearchedAddress(value.id).pipe(takeUntil(this.destroy$)).subscribe((response: NationaalGeoregisterLookupServerDocs): void => {
            this.webformService.normalForm.controls.report_location.setValue(response.weergavenaam, {emitEvent: false});
            this.webformService.normalForm.controls.report_postalcode.setValue(typeof response.postcode !== 'undefined' ? response.postcode : null);
            this.webformService.normalForm.controls.report_house_number.setValue(typeof response.huisnummer !== 'undefined' ? response.huisnummer : null);
            this.webformService.normalForm.controls.report_house_number_addition.setValue(typeof response.huisletter !== 'undefined' ? response.huisletter : null);
            this.webformService.normalForm.controls.report_streetname.setValue(typeof response.straatnaam !== 'undefined' ? response.straatnaam : null);
            this.webformService.normalForm.controls.report_city.setValue(typeof response.woonplaatsnaam !== 'undefined' ? response.woonplaatsnaam : null);
            this.webformService.normalForm.controls.report_municipality.setValue(typeof response.gemeentenaam !== 'undefined' ? response.gemeentenaam : null);
            this.webformService.normalForm.controls.report_lat.setValue(typeof response.lat !== 'undefined' ? response.lat : null);
            this.webformService.normalForm.controls.report_lng.setValue(typeof response.lng !== 'undefined' ? response.lng : null);

            if (this.webformService.normalForm.controls.same_as_reporter_address.value) {
              this.webformService.normalForm.controls.same_as_reporter_address.setValue(this.webformService.normalForm.controls.same_as_reporter_address.value);
            }

            this.leafletMapService.updateMarker([response.lat, response.lng]);
          });
        }
      }
    });

    this.webformService.normalForm.controls.same_as_reporter_address.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean): void => {
      if (value) {
        this.webformService.normalForm.controls.reporter_location.setValue(this.webformService.normalForm.controls.report_location.value, {emitEvent: false});
        this.webformService.normalForm.controls.reporter_postalcode.setValue(this.webformService.normalForm.controls.report_postalcode.value);
        this.webformService.normalForm.controls.reporter_house_number.setValue(this.webformService.normalForm.controls.report_house_number.value);
        this.webformService.normalForm.controls.reporter_house_number_addition.setValue(this.webformService.normalForm.controls.report_house_number_addition.value);
        this.webformService.normalForm.controls.reporter_streetname.setValue(this.webformService.normalForm.controls.report_streetname.value);
        this.webformService.normalForm.controls.reporter_city.setValue(this.webformService.normalForm.controls.report_city.value);
        this.webformService.normalForm.controls.reporter_municipality.setValue(this.webformService.normalForm.controls.report_municipality.value);
        this.webformService.normalForm.controls.reporter_lat.setValue(this.webformService.normalForm.controls.report_lat.value);
        this.webformService.normalForm.controls.reporter_lng.setValue(this.webformService.normalForm.controls.report_lng.value);
      } else {
        this.webformService.normalForm.controls.reporter_location.setValue(null, {emitEvent: false});
        this.webformService.normalForm.controls.reporter_postalcode.setValue(null);
        this.webformService.normalForm.controls.reporter_house_number.setValue(null);
        this.webformService.normalForm.controls.reporter_house_number_addition.setValue(null);
        this.webformService.normalForm.controls.reporter_streetname.setValue(null);
        this.webformService.normalForm.controls.reporter_city.setValue(null);
        this.webformService.normalForm.controls.reporter_municipality.setValue(null);
        this.webformService.normalForm.controls.reporter_lat.setValue(null);
        this.webformService.normalForm.controls.reporter_lng.setValue(null);
      }
    });

    this.webformService.normalForm.controls.reporter_anonymous.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean): void => {
      const element1 = this.webformService.normalForm.controls.reporter_type_id,
        element2 = this.webformService.normalForm.controls.reporter_email,
        element3 = this.webformService.normalForm.controls.same_as_reporter_address;
      if (value) {
        element1.removeValidators(Validators.required);
        element2.removeValidators(Validators.required);
        element3.removeValidators(Validators.required);

        this.webformService.normalForm.controls.reporter_type_id.setValue(null, {emitEvent: false});
        this.webformService.normalForm.controls.reporter_organisation.setValue(null, {emitEvent: false});
        this.webformService.normalForm.controls.reporter_prefix.setValue('Neutraal', {emitEvent: false});
        this.webformService.normalForm.controls.reporter_initials.setValue(null, {emitEvent: false});
        this.webformService.normalForm.controls.reporter_middlename.setValue(null, {emitEvent: false});
        this.webformService.normalForm.controls.reporter_lat.setValue(null, {emitEvent: false});
        this.webformService.normalForm.controls.reporter_location.setValue(null, {emitEvent: false});
        this.webformService.normalForm.controls.reporter_postalcode.setValue(null);
        this.webformService.normalForm.controls.reporter_house_number.setValue(null);
        this.webformService.normalForm.controls.reporter_house_number_addition.setValue(null);
        this.webformService.normalForm.controls.reporter_streetname.setValue(null);
        this.webformService.normalForm.controls.reporter_city.setValue(null);
        this.webformService.normalForm.controls.reporter_municipality.setValue(null);
        this.webformService.normalForm.controls.reporter_lat.setValue(null);
        this.webformService.normalForm.controls.reporter_lng.setValue(null);
        this.webformService.normalForm.controls.reporter_email.setValue(null, {emitEvent: false});
        this.webformService.normalForm.controls.reporter_phone.setValue(null, {emitEvent: false});
        this.webformService.normalForm.controls.reporter_notify.setValue(true, {emitEvent: false});
      } else {
        element1.addValidators(Validators.required);
        element2.addValidators(Validators.required);
        element3.addValidators(Validators.required);
      }
      element1.updateValueAndValidity();
      element2.updateValueAndValidity();
      element3.updateValueAndValidity();
    });

    this.webformService.normalForm.controls.reporter_initials.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
      this.webformService.normalForm.controls.reporter_initials.setValue([...value.toUpperCase().replace(/\./g, '')].join('.') + '.', {emitEvent: false});
    });

    this.webformService.normalForm.controls.reporter_lastname.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
      if (value.length > 0) {
        this.webformService.normalForm.controls.reporter_lastname.setValue(
          value.replace(/(^\w)(\S*)/g, (_: string, m1: string, m2: string) => m1.toUpperCase() + m2),
          {emitEvent: false}
        );
      }
    });

    this.webformService.normalForm.controls.reporter_location.valueChanges.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      filter((query: string): boolean | null => query && (typeof query === 'object' || typeof query === 'string' && (query.length > 2 ? query.length > 2 : null))),
      takeUntil(this.destroy$)
    ).subscribe((value: string | any): void => {
      this.suggestReporterLocations = [];

      this.webformService.normalForm.controls.reporter_postalcode.setValue(null);
      this.webformService.normalForm.controls.reporter_house_number.setValue(null);
      this.webformService.normalForm.controls.reporter_house_number_addition.setValue(null);
      this.webformService.normalForm.controls.reporter_streetname.setValue(null);
      this.webformService.normalForm.controls.reporter_city.setValue(null);
      this.webformService.normalForm.controls.reporter_municipality.setValue(null);
      this.webformService.normalForm.controls.reporter_lat.setValue(null);
      this.webformService.normalForm.controls.reporter_lng.setValue(null);

      if (value) {
        if (typeof value === 'string') {
          this.isLoadingReporterLocations = true;

          this.pdokService.getSearchedAddress(value).pipe(takeUntil(this.destroy$)).subscribe((response: NationaalGeoregisterSuggest): void => {
              this.isLoadingReporterLocations = false;

              if (response) {
                if (response.response.numFound > 0) {
                  this.suggestReporterLocations = response.response.docs;
                } else {
                  this.suggestReporterLocations = [{
                    id: null,
                    weergavenaam: 'Er zijn geen resultaten gevonden, probeer een ander adres.'
                  }];
                }
              }
            },
            (): boolean => this.isLoadingReporterLocations = false);
        } else {
          this.pdokService.selectSearchedAddress(value.id).pipe(takeUntil(this.destroy$)).subscribe((response: NationaalGeoregisterLookupServerDocs): void => {
            this.webformService.normalForm.controls.reporter_location.setValue(response.weergavenaam, {emitEvent: false});
            this.webformService.normalForm.controls.reporter_postalcode.setValue(typeof response.postcode !== 'undefined' ? response.postcode : null);
            this.webformService.normalForm.controls.reporter_house_number.setValue(typeof response.huisnummer !== 'undefined' ? response.huisnummer : null);
            this.webformService.normalForm.controls.reporter_house_number_addition.setValue(typeof response.huisletter !== 'undefined' ? response.huisletter : null);
            this.webformService.normalForm.controls.reporter_streetname.setValue(typeof response.straatnaam !== 'undefined' ? response.straatnaam : null);
            this.webformService.normalForm.controls.reporter_city.setValue(typeof response.woonplaatsnaam !== 'undefined' ? response.woonplaatsnaam : null);
            this.webformService.normalForm.controls.reporter_municipality.setValue(typeof response.gemeentenaam !== 'undefined' ? response.gemeentenaam : null);
            this.webformService.normalForm.controls.reporter_lat.setValue(typeof response.lat !== 'undefined' ? response.lat : null);
            this.webformService.normalForm.controls.reporter_lng.setValue(typeof response.lng !== 'undefined' ? response.lng : null);
          });
        }
      }
    });

    this.webformService.normalForm.controls.reporter_notify.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean): void => {
      const element: FormControl = this.webformService.normalForm.controls.reporter_email;
      if (value) {
        element.addValidators(Validators.required);
      } else {
        element.removeValidators(Validators.required);
      }
      element.updateValueAndValidity();
    });

    if (this.webformService.normalForm.controls.attachments.value.length) {
      const formArray: FormArray = this.webformService.normalForm.get('attachments') as FormArray;

      this.webformService.normalForm.controls.attachments.value.forEach((file: File): void => {
        const component: ComponentRef<RemovableAttachmentComponent> = this.attachmentsPlaceholder.createComponent(RemovableAttachmentComponent);
        component.instance.filename = file.name;
        component.instance.size = file.size;

        this.attachmentsRef.push(component);

        component.instance.destroy.subscribe((): void => {
          const index: number = formArray.controls.findIndex(x => x.value.name === file.name);
          formArray.removeAt(index);
          component.destroy();
        });
      });
    }
  }

  onSectionChange(section: string): void {
    this.currentSection = section ?? 'melding';
    this.cd.detectChanges();
  }

  detectUploadAttachments(event: any): void {
    if (typeof event !== 'undefined') {
      if (typeof event.target !== 'undefined') {
        if (typeof event.target.files !== 'undefined') {
          const formArray: FormArray = this.webformService.normalForm.get('attachments') as FormArray;
          let totalSize: number = 0;

          Object.keys(this.webformService.normalForm.controls.attachments.value).map((d: string): void => {
            totalSize += this.webformService.normalForm.controls.attachments.value[d].size;
          });

          Object.keys(event.target.files).map((file: string): void => {
            if (this.webformService.normalForm.controls.attachments.length < this.acceptedMaxAttachments) {
              if (this.acceptedAttachments.includes(event.target.files[file].name.split('.').pop().toLowerCase())) {
                if ((totalSize += event.target.files[file].size) < this.acceptedMaxSizeAttachments) {
                  const component: ComponentRef<RemovableAttachmentComponent> = this.attachmentsPlaceholder.createComponent(RemovableAttachmentComponent);
                  component.instance.filename = event.target.files[file].name;
                  component.instance.size = event.target.files[file].size;

                  this.attachmentsRef.push(component);

                  const control: FormControl<File> = new FormControl(event.target.files[file]);
                  formArray.push(control);

                  component.instance.destroy.subscribe((): void => {
                    const index: number = formArray.controls.findIndex(x => x.value.name === event.target.files[file].name);
                    formArray.removeAt(index);
                    component.destroy();
                  });
                } else {
                  this.attachmentTotalSizeExceeded = true;
                  setTimeout((): boolean => this.attachmentTotalSizeExceeded = false, 10000);
                }
              } else {
                this.attachmentIgnored = event.target.files[file].name;
                setTimeout((): void => this.attachmentIgnored = null, 10000);
              }
            } else {
              this.attachmentCountExceeded = true;
              setTimeout((): boolean => this.attachmentCountExceeded = false, 10000);
            }
          });
        }
      }
    }
  }

  formatSuspectNameDropdown(result: any): string {
    return result !== null && typeof result === 'object' ? result.name : result;
  }

  formatLocationDropdown(result: any): string {
    return result !== null && typeof result === 'object' ? result.weergavenaam : result;
  }

  onMapClick(event: any): void {
    this.webformService.normalForm.controls.report_location.setValue(null);
    if (this.webformService.normalForm.controls.same_as_reporter_address.value) {
      this.webformService.normalForm.controls.same_as_reporter_address.setValue(this.webformService.normalForm.controls.same_as_reporter_address.value);
    }

    this.pdokService.doReverseSearch([event.latlng.lat, event.latlng.lng]).pipe(takeUntil(this.destroy$)).subscribe((result: NationaalGeoregisterRevGeoServerDocs): void => {
      if (result) {
        if (this.shouldGeocodeReportLocation === true) {
          this.pdokService.selectSearchedAddress(result.id).pipe(takeUntil(this.destroy$)).subscribe((response: NationaalGeoregisterLookupServerDocs): void => {
            this.webformService.normalForm.controls.report_location.setValue(response.weergavenaam, {emitEvent: false});
            this.webformService.normalForm.controls.report_postalcode.setValue(typeof response.postcode !== 'undefined' ? response.postcode : null);
            this.webformService.normalForm.controls.report_house_number.setValue(typeof response.huisnummer !== 'undefined' ? response.huisnummer : null);
            this.webformService.normalForm.controls.report_house_number_addition.setValue(typeof response.huisletter !== 'undefined' ? response.huisletter : null);
            this.webformService.normalForm.controls.report_streetname.setValue(typeof response.straatnaam !== 'undefined' ? response.straatnaam : null);
            this.webformService.normalForm.controls.report_city.setValue(typeof response.woonplaatsnaam !== 'undefined' ? response.woonplaatsnaam : null);
            this.webformService.normalForm.controls.report_municipality.setValue(typeof response.gemeentenaam !== 'undefined' ? response.gemeentenaam : null);
            this.webformService.normalForm.controls.report_lat.setValue(typeof response.lat !== 'undefined' ? response.lat : null);
            this.webformService.normalForm.controls.report_lng.setValue(typeof response.lng !== 'undefined' ? response.lng : null);

            if (this.webformService.normalForm.controls.same_as_reporter_address.value) {
              this.webformService.normalForm.controls.same_as_reporter_address.setValue(this.webformService.normalForm.controls.same_as_reporter_address.value);
            }

            this.leafletMapService.updateMarker([response.lat, response.lng]);
          });
        } else {
          this.pdokService.selectSearchedAddress(result.id).pipe(takeUntil(this.destroy$)).subscribe((response: NationaalGeoregisterLookupServerDocs): void => {
            this.webformService.normalForm.controls.report_location.setValue(event.latlng.lat + ', ' + event.latlng.lng, {emitEvent: false});
            this.webformService.normalForm.controls.report_postalcode.setValue(typeof response.postcode !== 'undefined' ? response.postcode : null);
            this.webformService.normalForm.controls.report_house_number.setValue(null);
            this.webformService.normalForm.controls.report_house_number_addition.setValue(null);
            this.webformService.normalForm.controls.report_city.setValue(typeof response.woonplaatsnaam !== 'undefined' ? response.woonplaatsnaam : null);
            this.webformService.normalForm.controls.report_municipality.setValue(typeof response.gemeentenaam !== 'undefined' ? response.gemeentenaam : null);
            this.webformService.normalForm.controls.report_lat.setValue(event.latlng.lat);
            this.webformService.normalForm.controls.report_lng.setValue(event.latlng.lng);

            this.leafletMapService.updateMarker([event.latlng.lat, event.latlng.lng]);
          });
        }
      }
    });
  }

  validateReportLocation(): void {
    const controls = this.webformService.normalForm.controls;
    console.log(controls.reporter_location);
    if (controls.report_location.value) {
      if (controls.report_lat.value === null) {
        controls.report_location.setErrors({invalid: true});
      } else {
        controls.report_location.setErrors(null);
      }
    }
  }

  checkForm(): void {
    this.controlForm = true;
    if (this.webformService.normalForm.valid) {
      this.webformService.checkForm = true;
      this.router.navigate(['/controle']).then((): void => {
      });
    }
  }

  get isLocal(): boolean {
    return window.location.href.indexOf('localhost') !== -1;
  }

  log(): void {
    console.log(this.webformService.normalForm);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
